






















































































































































































import * as R from 'ramda';
import dayjs from 'dayjs';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { DropdownMenu } from '@/app/components';
import { ModelsAPI } from '../api';
import { Status } from '../constants';

export default defineComponent({
    name: 'ModelOverview',
    props: {
        model: {
            type: Object,
            required: true,
        },
        strikeThroughDeprecated: {
            type: Boolean,
            default: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },
    components: { DropdownMenu },
    setup(props: any, { emit }: { emit: any }) {
        const modelMajorVersion = ref<any>(null);
        const numberOfActiveConcepts = ref(null);
        const numberOfActiveLeafs = ref(null);
        const lastUpdatedOn = ref(null);
        const dateDeprecated = ref(null);
        const options = ref<any[]>([]);
        const loading = ref(false);

        const isDraft = computed(() => props.model.status === Status.Draft);
        const isStable = computed(
            () => props.model.status === Status.Stable && props.model.majorVersion === modelMajorVersion.value,
        );
        const inReview = computed(() => props.model.status === Status.UnderRevision);
        const isUnderRevision = computed(
            () => props.model.status === Status.Stable && props.model.majorVersion < modelMajorVersion.value,
        );
        const isDeprecated = computed(() => props.model.status === Status.Deprecated);
        loading.value = true;
        ModelsAPI.getDomainMajorVersion(props.model.uid)
            .then((res: any) => {
                modelMajorVersion.value = res.data.majorVersion;

                if (isStable.value) {
                    options.value.push(
                        {
                            name: 'Edit',
                            action: () => {
                                emit('edit', props.model);
                            },
                        },
                        {
                            name: 'Clone',
                            requiresConfirm: true,
                            action: () => {
                                emit('clone', props.model);
                            },
                        },
                        {
                            name: 'Deprecate',
                            requiresConfirm: true,
                            action: () => {
                                emit('delete', props.model, 'deprecate');
                            },
                        },
                    );
                    if (props.admin) {
                        options.value.push({
                            name: 'Export',
                            action: () => {
                                emit('export', props.model);
                            },
                        });
                    }
                }
            })
            .finally(() => {
                loading.value = false;
            });

        if (isDraft.value) {
            options.value.push(
                {
                    name: 'Edit',
                    action: () => {
                        emit('edit', props.model);
                    },
                },
                {
                    name: 'Publish/Release',
                    requiresConfirm: true,
                    action: () => {
                        emit('publish', props.model);
                    },
                },
                {
                    name: 'Clone',
                    requiresConfirm: true,
                    action: () => {
                        emit('clone', props.model);
                    },
                },
                {
                    name: 'Delete',
                    requiresConfirm: true,
                    action: () => {
                        emit('delete', props.model, 'delete');
                    },
                },
            );
        } else if (inReview.value) {
            options.value.push(
                {
                    name: 'Publish/Release',
                    requiresConfirm: true,
                    action: () => {
                        emit('publish', props.model);
                    },
                },
                {
                    name: 'Clone',
                    requiresConfirm: true,
                    action: () => {
                        emit('clone', props.model);
                    },
                },
                {
                    name: 'Cancel Revision',
                    requiresConfirm: true,
                    action: () => {
                        emit('delete', props.model, 'delete');
                    },
                },
            );
        }
        loading.value = true;
        ModelsAPI.metadata(props.model.id).then((res: any) => {
            if (!R.isNil(res) && !R.isNil(res.data)) {
                numberOfActiveConcepts.value = res.data.concepts;
                numberOfActiveLeafs.value = res.data.fields;
                lastUpdatedOn.value = res.data.lastUpdatedOn;
                dateDeprecated.value = res.data.dateDeprecated;
            }
            loading.value = false;
        });

        return {
            dateDeprecated,
            dayjs,
            inReview,
            isDeprecated,
            isDraft,
            isStable,
            isUnderRevision,
            lastUpdatedOn,
            loading,
            numberOfActiveConcepts,
            numberOfActiveLeafs,
            options,
            Status,
        };
    },
});
