





























































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import store from '@/app/store';
import { ButtonGroup, Card, FormModal, SvgImage, Pagination } from '@/app/components';
import { UserRoles } from '@/app/constants';
import { LoadingModal, ModelOverview } from '@/modules/data-model/components';
import { ModelsAPI } from '../api';
import { useDataModel } from '../composable';
import EditModel from '../components/EditModel.vue';
import { Status } from '../constants';

export default defineComponent({
    name: 'Models',
    components: {
        ButtonGroup,
        Card,
        EditModel,
        FormModal,
        LoadingModal,
        ModelOverview,
        OrbitSpinner,
        SvgImage,
        Pagination,
    },
    setup(props: any, { root }: { root: any }) {
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const models = ref<any[]>([]);
        const currentFilter = ref<string>('active');
        const createModel = ref<boolean>(false);
        const currentModel = ref<any>(null);
        const error = ref(null);
        const loading = ref(false);
        const loadingModel = ref(false);
        const updateModelError = ref(null);
        const action = ref<string>('');

        const { filterConcepts } = useDataModel();

        const filteredModels = computed(() => {
            return filterConcepts(Status.Stable, models.value, currentFilter.value);
        });

        const pageSize = 10;
        const page = ref<number>(1);
        const modelsElement = ref<HTMLElement>();

        const setPage = (newPage: number) => {
            page.value = newPage;
            if (modelsElement.value) {
                modelsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };
        const currentPage = computed({
            get: () => page.value,
            set: (newPage: number) => {
                setPage(newPage);
            },
        });

        const visibleModels = computed(() => {
            if (filteredModels.value) {
                return filteredModels.value.slice((page.value - 1) * pageSize, (page.value - 1) * pageSize + pageSize);
            }
            return [];
        });

        const isCurrentModelDraft = computed(() => currentModel.value && currentModel.value.status === Status.Draft);

        const fetchModels = () => {
            loading.value = true;
            ModelsAPI.all().then((res: any) => {
                models.value = res.data;
                loading.value = false;
            });
        };

        const edit = (model: { id: number; name: string }) => {
            currentModel.value = model;
        };

        const clone = (model: { id: number; name: string }) => {
            loadingModel.value = true;
            action.value = 'clone';

            ModelsAPI.clone(model.id)
                .then((res: any) => {
                    (root as any).$toastr.s(`Model '${model.name}' is cloned`, 'Cloned');
                    root.$router.push({ name: 'model-manager:edit', params: { id: res.data.id } });
                    fetchModels();
                })
                .catch((e) => {
                    error.value = e;
                    (root as any).$toastr.e(
                        `Model '${model.name}' could not be cloned with error ${e.message}`,
                        'Failed to clone',
                    );
                })
                .then(() => {
                    loadingModel.value = false;
                    action.value = '';
                });
        };

        const publish = async (model: { id: number; name: string }) => {
            let forbiddenHLConceptNames: any = [];
            await ModelsAPI.getConcepts(model.id).then((res: any) => {
                // don't allow publishing of a model if high level concepts' names begin with '@'
                forbiddenHLConceptNames = res.data.filter((hlConcept: any) => hlConcept.name.trim()[0] === '@');

                if (!forbiddenHLConceptNames.length) {
                    loadingModel.value = true;
                    action.value = 'publish';
                    ModelsAPI.publish(model.id)
                        .then(() => {
                            fetchModels();
                            currentFilter.value = 'active';
                            (root as any).$toastr.s(`Model '${model.name}' is now published`, 'Published');
                        })
                        .catch((e) => {
                            error.value = e;
                            (root as any).$toastr.e(
                                `Model '${model.name}' could not be published with error ${e.message}`,
                                'Failed to publish',
                            );
                        })
                        .finally(() => {
                            loadingModel.value = false;
                            action.value = '';
                        });
                } else {
                    (root as any).$toastr.e(
                        `Model '${model.name}' could not be published with error: The names of High Level Concepts cannot start with the special character '@', which signifies that they have been copied from another Model. Please rename the concepts.`,
                        'Failed to publish',
                    );
                }
            });
        };

        const deleteDeprecate = (model: { id: number; name: string }, deleteAction: string) => {
            loadingModel.value = true;
            action.value = deleteAction;
            ModelsAPI.delete(model.id)
                .then((res: any) => {
                    fetchModels();
                    setPage(1);
                    if (res.data) {
                        currentFilter.value = 'deprecated';
                        (root as any).$toastr.s(`Model '${model.name}' is now deprecated`, 'Deprecated');
                    } else {
                        (root as any).$toastr.s(`Model '${model.name}' is now deleted`, 'Deleted');
                    }
                })
                .catch((e) => {
                    error.value = e;
                    (root as any).$toastr.e(
                        `Model '${model.name}' could not be deleted with error ${e.message}`,
                        'Failed to delete',
                    );
                })
                .then(() => {
                    loadingModel.value = false;
                    action.value = '';
                });
        };

        const exportModel = (model: { id: number; name: string }) => {
            loadingModel.value = true;
            action.value = 'export';
            ModelsAPI.export(model.id)
                .then((res: any) => {
                    const blob = new Blob(res.data, { type: 'text/plain;charset=utf-8' });
                    const link = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.download = `${Date.now()}-${model.name}.json`;
                    a.href = link;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                })
                .catch((e: any) => {
                    error.value = e;
                    (root as any).$toastr.e(
                        `Model '${model.name}' could not be exported with error ${e.message}`,
                        'Failed to export',
                    );
                })
                .then(() => {
                    loadingModel.value = false;
                    action.value = '';
                });
        };

        const filterModels = (selection: string) => {
            setPage(1);
            currentFilter.value = selection;
        };

        const saveModel = () => {
            updateModelError.value = null;
            if (!currentModel.value.id) {
                loading.value = true;
                ModelsAPI.createConcept(currentModel.value)
                    .then((res: any) => {
                        if (res && res.status >= 200 && res.status < 400) {
                            (root as any).$toastr.s(
                                `Model '${res.data.name}' successfully created as draft`,
                                'Created',
                            );
                            root.$router.push({ name: 'model-manager:edit', params: { id: res.data.id } });
                        } else {
                            (root as any).$toastr.e(`Failed to create model`, 'Failed');
                        }
                        currentModel.value = null;
                        createModel.value = false;
                        updateModelError.value = null;
                    })
                    .catch((e) => {
                        updateModelError.value = e.response.data.message;
                    })
                    .then(() => {
                        loading.value = false;
                        fetchModels();
                    });
            } else {
                loading.value = true;
                const modelWithoutChildren = { ...currentModel.value, children: [] };
                ModelsAPI.updateConcept(currentModel.value.id, modelWithoutChildren)
                    .then(() => {
                        (root as any).$toastr.s(`Model '${currentModel.value.name}' successfully updated`, 'Updated');
                        currentModel.value = null;
                        fetchModels();
                    })
                    .catch((e) => {
                        updateModelError.value = e.response.data.message;
                    })
                    .then(() => {
                        loading.value = false;
                    });
            }
        };

        const uploadModel = async (event: any) => {
            const file = event.target.files[0];
            const data = await file.text();
            let json = null;
            try {
                loadingModel.value = true;
                action.value = 'import';
                json = JSON.parse(data);
                ModelsAPI.import(json)
                    .then(() => {
                        currentFilter.value = 'draft';
                        (root as any).$toastr.s('Model has been imported successfully as Draft', 'Imported');
                        fetchModels();
                    })
                    .catch((e) => {
                        (root as any).$toastr.e(
                            `${
                                e.response && e.response.data && e.response.data.message
                                    ? e.response.data.message
                                    : 'Failed to import model'
                            }`,
                            'Error',
                        );
                    })
                    .finally(() => {
                        loadingModel.value = false;
                        action.value = '';
                    });
            } catch (e) {
                (root as any).$toastr.e('Invalid JSON format!', 'Error');
                loadingModel.value = false;
                action.value = '';
            }
        };

        fetchModels();

        return {
            action,
            clone,
            createModel,
            currentFilter,
            currentModel,
            edit,
            error,
            deleteDeprecate,
            filteredModels,
            filterModels,
            isCurrentModelDraft,
            loading,
            loadingModel,
            publish,
            saveModel,
            updateModelError,
            exportModel,
            uploadModel,
            isAdmin,
            pageSize,
            currentPage,
            visibleModels,
            modelsElement,
        };
    },
});
